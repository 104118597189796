<template>
  <div class="d3g--chat-input">
    <div class="d3g--textarea-wrapper">
      <span class="textarea" role="textbox" ref="chat-input-box" contenteditable></span>
    </div>
    <button class="d3g--submit-btn" @click="sendMessage">Send</button>
  </div>
</template>

<script>
import ChatService from '@services/ChatService'
import Config from '@services/Config'

export default {
  name: 'chat-input',
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      msgText: '',
    }
  },
  computed: {
    channelIdentifier () {
      return this.channel.identifier
    },
  },
  methods: {
    sendMessage () {
      console.log('sendMessage', this.msgText, this.$refs['chat-input-box'].innerText)
      this.msgText = this.$refs['chat-input-box'].innerText
      if (this.msgText.length > 0) {
        ChatService.sendMessage(this.msgText, this.channelIdentifier)
        this.msgText = ''
        this.$refs['chat-input-box'].innerText = ''
      }
    },
    listenerMethod (event) {
      if (event.keyCode === 13 && !event.shiftKey) {
        event.preventDefault()
        this.sendMessage()
        return false
      }
    },
  },
  mounted () {
    const input = this.$refs['chat-input-box']
    input.addEventListener('keydown', this.listenerMethod)
  },
  destroyed () {
    const input = this.$refs['chat-input-box']
    input.removeEventListener('keydown', this.listenerMethod)
  },
}
</script>

<style scoped lang="scss">
  .d3g--chat-input {
    display: flex;
    // flex-grow: 1;

    .d3g--submit-btn {
      flex: 0 0 85px;
      margin-left: 0;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      background-color: #9b9b9b;
      font-size: 0.875rem;
      font-weight: 500;
      border-width: 0;
      border-radius: 0;
      color: rgba(255, 255, 255, 0.95);
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
      transition-duration: 150ms;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      cursor: pointer;

      &:hover {
        font-weight: 600;
        color: white;
      }
    }

    .d3g--textarea-wrapper {
      position: relative;
      height: auto;
      padding: 4px 8px;
      flex-grow: 1;
      border: 1px solid #9b9b9b;
      border-radius: 0;
      border-right-width: 0px;
      background: white;

      span {
        display: block;
        width: 100%;
        max-width: 100%;
        min-height: 1.3rem;
        overflow-y: auto;
        overflow-x: hidden;
        overflow-wrap: anywhere;
        font-size: 0.9rem;
        max-height: 120px;
        outline-width: 0;
        border-radius: 0;
      }
    }
  }
</style>