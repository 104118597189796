import axios from 'axios'
import { memoize } from 'lodash-es'
import Config from '@services/Config'

const api = memoize(() =>
  axios.create({
    baseURL: Config.get('node-server-url'),
  })
)

export function forceStartServer(eventSessionExternalId) {
  return api().post(`/servers/${eventSessionExternalId}/start`)
}

export function forceStopServer(eventSessionExternalId) {
  return api().post(`/servers/${eventSessionExternalId}/stop`)
}
