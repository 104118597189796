<template>
  <div class="d3g--chat-log" ref="chatLog">
    <div v-if="userIsAdmin" class="d3g--fetch-history" @click="clickFetchHistory()">Fetch more messages</div>
    <message v-for="msg in messages" :key="msg.timeToken" :data="msg" />
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import Config from '@services/Config'
import ChatService from '@services/ChatService'
import Message from '@components/Message.vue'

export default {
  name: 'chat-log',
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  components: {
    Message,
  },
  data () {
    return {
      messages: [],
      currentHistoryTimestamp: Date.now() * Math.pow(10, 4),
      fetchingHistory: false,
      historyPromise: null,
      scrollToTop: false,
      debouncedAutoScroll: debounce(() => {
        const logEl = this.$refs.chatLog
        if (this.scrollToTop) {
          logEl.scrollTop = 0
          this.scrollToTop = false
        } else {
          logEl.scrollTop = logEl.scrollHeight
        }
      }, 50)
    }
  },
  computed: {
    channelIdentifier () {
      return this.channel.identifier
    },
    userIsAdmin () {
      return ChatService.userIsAdmin
    },
  },
  methods: {
    clickFetchHistory () {
      this.scrollToTop = true
      return this.fetchHistory()
    },
    fetchHistory () {
      if (this.fetchingHistory) {
        return this.historyPromise
      }

      this.fetchingHistory = true
      if (this.userIsAdmin) {
        this.historyPromise = ChatService.fetchHistoryAsAdmin(this.channelIdentifier, this.currentHistoryTimestamp)
          .then(res => {
            this.currentHistoryTimestamp = res.newHistoryCurrentTimestamp
            this.fetchingHistory = false
          })
      } else {
        this.historyPromise = ChatService.fetchHistoryAsUser(this.channelIdentifier, this.currentHistoryTimestamp)
          .then(res => {
            this.fetchingHistory = false
          })
      }

      return this.historyPromise
    },
    fetchMessages () {
      this.messages = ChatService.messagesByChannel[this.channelIdentifier]
      setTimeout(this.debouncedAutoScroll, 0)
    },
    subscribeToChannel (channel) {
      if (!ChatService.hasSubscriptionToChannel(channel)) {
        ChatService.subscribeChannel(this.channelIdentifier, this.fetchMessages)
        this.fetchHistory()
      }
      this.fetchMessages()
    },
  },
  mounted () {
    this.subscribeToChannel(this.channelIdentifier)
  },
  watch: {
    channel (newVal, oldVal) {
      if (newVal && newVal.identifier !== oldVal.identifier) {
        this.subscribeToChannel(newVal)
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .d3g--chat-log {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 0 10px 10px 10px;
    overflow-y: scroll;
    background: #F8F8F8;
    height: 0;

    .d3g--fetch-history {
      display: inline-block;
      font-size: 0.7rem;
      color: #aaa;
      // position: relative;
      // top: -8px;
      background-color: #eee;
      // left: 
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: #888;
      }
    }
  }
</style>