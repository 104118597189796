<template>
  <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label for="username" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Session Name
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        {{ eventSessionData.name }}
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        External ID
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        {{ eventSessionData.external_id }}
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label for="country" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Primary Language
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        {{ eventSessionData.primary_language_code }}
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label for="country" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Translatable Languages
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        {{ eventSessionData.translatable_language_codes }}
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label for="country" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Session Date
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        {{ eventSessionData.session_date }}
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label for="status" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        Stream Status
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        {{ eventSessionData.status }}

        <div v-if="eventSessionData.session_date === today">
          <button
            v-if="eventSessionData.status === 'streaming' || eventSessionData.status == 'ready'"
            v-on:click="handleUpdateRtmpServer('stop')"
            :disabled="rtmpServerState === 'loading'"
            type="button"
            class="btn btn-primary"
          >
            Stop Stream
          </button>

          <button
            v-else
            v-on:click="handleUpdateRtmpServer('start')"
            :disabled="rtmpServerState === 'loading'"
            type="button"
            class="btn btn-primary"
          >
            Start Stream
          </button>
        </div>
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <label for="country" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
        RTMP URL
      </label>
      <div class="mt-1 sm:mt-0 sm:col-span-2">
        {{ eventSessionData.rtmp_url }}
      </div>
    </div>

    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
      <div class="flex flex-col">
        <p class="text-sm font-medium text-gray-900" id="privacy-option-3-label">
          Show Partials
        </p>
        <p class="text-sm text-gray-500" id="privacy-option-3-description">
          Display transcribed partials for the primary language in real time.
        </p>
      </div>
      <input type="checkbox" name="show_partials" id="show_partials" value="true" disabled="disabled" v-model="eventSessionData.show_partials" />
    </div>
  </div>
</template>

<script>
import format from "date-fns/format";
import Config from "@services/Config";
import { updateEventSession } from "@services/EventSession";
import { forceStartServer, forceStopServer } from "@services/StreamServer";

export default {
  name: 'event-session-status-app',
  props: {
    eventSession: {
      type: String,
      required: true,
    },
    rtmpServerIp: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      eventSessionData: {},
      today: format(new Date(), "yyyy-MM-dd"),
      rtmpServerState: 'initial'
    }
  },
  computed: {
    pubnubChannelName() {
      return `event-session.update.${this.eventSessionData.id}`
    }
  },
  created() {
    this.eventSessionData = JSON.parse(this.eventSession)

    const pubnub = new PubNub({
      subscribeKey: Config.get('pubnub-subscribe-key'),
      publishKey: Config.get('pubnub-publish-key'),
      secretKey: Config.get('pubnub-secret-key'),
      uuid: Config.get('pubnub-user-uuid'),
      authKey: Config.get('pubnub-user-auth-key'),
      ssl: true,
    })
 
    pubnub.subscribe({
      channels: [this.pubnubChannelName],
    })

    pubnub.addListener({
      message: (evt) => {
        if (evt.channel === this.pubnubChannelName) {
          this.updateEventSessionData(evt.message.event_session)
        }
      },
    })

    this.pubnub = pubnub
  },
  methods: {
    onInit (evt) {
      this.loaded = true
    },
    updateEventSessionData(updatedEventSessionData) {
      Object
        .keys(updatedEventSessionData)
        .forEach((key) => {
          this.$set(
            this.eventSessionData,
            key,
            updatedEventSessionData[key]
          )
        })
    },
    handleUpdateRtmpServer(action) {
      const rtmpServerRequest = action === 'start' ? forceStartServer : forceStopServer

      this.rtmpServerState = 'loading'

      updateEventSession(this.eventSessionData.id, { session_date: this.today })
        .then(() => rtmpServerRequest(this.eventSessionData.external_id))
        .then(() => console.log('Success!'))
        .catch((err) => console.error('Error:', err))
        .then(() => {
          this.rtmpServerState = 'initial'
        })
    },
  },
}
</script>
