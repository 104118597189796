<template>
  <div class="p-4 pt-2 bg-gray-100">
    <form v-on:submit.prevent="handleSubmit">
      <div>
        <label for="transcript-text" class="cursor-pointer block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">
          Edit Transcript
        </label>
        <div class="mt-2">
          <textarea
            id="transcript-text"
            class="form-input flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-md sm:text-sm border-gray-300 h-48 disabled:bg-gray-300"
            v-model="text"
            :disabled="formState === 'loading'"
            @input="handleTranscriptEdit"
          ></textarea>
        </div>
      </div>

      <div class="pt-5">
        <div class="flex items-center justify-between">
          <div>
            <span v-if="formState === 'failure'" class="text-sm text-red-700">
              Error updating, please try again.
            </span>
          </div>
          <button class="btn btn-primary inline-flex items-center" type="submit" :disabled="formState === 'loading'">
            Update
            <svg v-if="formState === 'loading'" class="animate-spin -mr-1 ml-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { updateTranscriptionBlock } from '@services/EventSession';

export default {
  name: 'transcription-edit',
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      formState: 'initial',
      text: this.message.text
    }
  },
  methods: {
    handleSubmit() {
      this.formState = 'loading'

      updateTranscriptionBlock(this.message.id, { text: this.text })
        .then(() => {
          this.$emit('onUpdate')
        })
        .catch(() => {
          this.formState = 'failure'
        })
    },
    handleTranscriptEdit() {
      this.formState = 'initial'
    }
  }
}
</script>
