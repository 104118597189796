<template>
  <div class="d3g--chat-channels">
    <div class="d3g--channel-tab"
      v-for="ch in availableChannels"
      :key="ch.identifier" @click="select(ch)">
      <div class="d3g--label" :class="channel && channel.identifier === ch.identifier ? 'd3g--active': ''">{{ ch.name }}</div>
    </div>
  </div>
</template>

<script>
import ChatService from '@services/ChatService'
import Config from '@services/Config'

export default {
  name: 'chat-channels',
  props: {
    channel: {
      type: Object,
    },
  },
  data () {
    return {
      availableChannels: ChatService.availableChannels,
    }
  },
  computed: {

  },
  methods: {
    select (channel) {
      this.$emit('channel-changed', channel)
    },
  },
  created () {
    if (!this.channel) {
      this.select(ChatService.availableChannels[0])
    }
  },
}
</script>

<style scoped lang="scss">
  .d3g--chat-channels {
    display: flex;
    background-color: #10eb1e;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    flex-basis: 41px;

    .d3g--channel-tab {
      margin-bottom: -1px;
      margin-right: 0;

      .d3g--label {
        display: inline-block;
        height: 100%;
        color: white;
        padding: 0.5rem 1rem 0.5rem 1rem;
        font-weight: 600;
        cursor: pointer;
        transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
        transition-duration: 150ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

        &.d3g--active {
          color: black;
          border-width: 0px;
          background-color: white;
        }
      }
    }

  }
</style>