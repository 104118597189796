<template>
  <div class="d3g--message">
    <div class="d3g--message-wrapper">
      <div class="d3g--date">{{ timestamp }}</div>
      <div class="d3g--message-blob">
        <span class="d3g--username-embed" :class="messageSentByAdmin ? 'd3g--is-admin' : ''">{{ name }}</span>
        <span class="d3g--message-text">- {{ text }}</span>
      </div>
      <template v-if="userIsAdmin">
        <div class="d3g--delete-message" @click="deleteMessage">D</div>
        <div class="d3g--ban-user" @click="banUser">B</div>
      </template>
    </div>
  </div>
</template>

<script>
import ChatService from '@services/ChatService'
import { DateTime } from 'luxon'

export default {
  name: 'message',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name () {
      return this.data.message.name
    },
    text () {
      return this.data.message.text.replace(/&nbsp;/gi, '')
    },
    timestamp () {
      const unix = parseInt(parseInt(this.data.timetoken) / Math.pow(10, 4))
      return DateTime.fromMillis(unix).toFormat('h:mm')
    },
    messageSentByAdmin () {
      return this.data.message.publisher && this.data.message.publisher.includes('admin:')
    },
    userIsAdmin () {
      return ChatService.userIsAdmin
    },
  },
  methods: {
    deleteMessage () {
      if (this.userIsAdmin) {
        ChatService.deleteMessage(this.data)
      }
    },
    banUser () {
      if (this.userIsAdmin) {
        ChatService.banUser(this.data)
      }
    },
  },
}
</script>

<style lang="scss">
.d3g--message {
  padding: 3px;
  font-family: sans-serif;
  align-items: center;

  &:first-child {
    margin-top: auto;
  }

  .d3g--date {
    font-weight: 500;
    font-size: 0.7rem;
    margin-right: 6px;
    color: rgb(88, 88, 88);
  }

  .d3g--message-wrapper {
    display: flex;
    align-items: center;
  }

  .d3g--message-blob {
    font-size: 0.9rem;
    text-align: left;
    word-break: break-word;
    white-space: pre-wrap;
    border-radius: 5px 5px;
    padding: 5px 10px;
    min-width: 75px;
    width: fit-content;
    background: white;
    box-shadow: rgba(103, 19, 176, 0.06) 1px 2px 3px;
    color: rgb(88, 88, 88);

    .d3g--username-embed {
      font-weight: 600;
      font-size: 0.9rem;
      text-transform: capitalize;
      color: rgb(88, 88, 88);
      margin-right: 2px;

      &.d3g--is-admin {
        color: #10eb1e;
      }
    }
  }

  .d3g--message-text {
    width: fit-content;
    color: rgb(88, 88, 88);
    font-size: 0.9rem;
    text-align: left;
  }
}
</style>