<template>
  <div class="d3g--chat-app" ref="d3g-chat-root">
    <chat v-if="loaded" @close-drawer="panelOpened = false" />
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import ChatService from '@services/ChatService'
import Chat from '@components/Chat.vue'
import Config from '@services/Config'
import EventBus from '@services/EventBus'
import { htmlDecode } from '@utils'

export default {
  name: 'chat-app',
  components: {
    Chat,
  },
  data () {
    return {
      loaded: false,
      panelOpened: false,
    }
  },
  created () {
    ChatService.init(Config.get('me'))
      .then(() => {
        this.loaded = true
      })
    const chatButton = document.createElement('div')
    chatButton.classList.add('d3g--chat-button')
    chatButton.innerText = 'Open Event Chat'
    chatButton.addEventListener('click', evt => {
      ChatService.checkForName()
      this.panelOpened = !this.panelOpened
    })
    document.body.append(chatButton)
  },
  watch: {
    panelOpened (newVal, oldVal) {
      if (newVal !== oldVal) {
        const root = this.$refs['d3g-chat-root']
        newVal ? root.classList.add('d3g--opened') : root.classList.remove('d3g--opened')
      }
    },
  },
}
</script>

<style scoped lang="scss">
  .d3g--chat-app {
    position: fixed;
    max-height: 600px;
    min-height: 500px;
    height: calc(100% - 80px);
    width: 100%;
    max-width: 450px;
    z-index: 100000;
    right: -450px;
    bottom: 0;
    transition: right 0.2s ease-out;

    font-family: 'BWHaasGrotesk-55Roman-Web', 'Open Sans', sans-serif;

    &.d3g--opened {
      right: 5px;
    }
  }
</style>

<style lang="scss">
  .d3g--chat-button {
    position: fixed;
    bottom: 0px;
    right: 5px;
    padding: 10px 20px;
    background-color: #14B91E;
    color: #f5f5f5;
    font-weight: 600;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'BWHaasGrotesk-75Bold-Web', 'Open Sans', sans-serif;

    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      background-color: #14eb1e;
    }
  }
</style>
