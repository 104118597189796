export function htmlDecode (input) {
  var doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent
}

export function harvestContextData () {
  var data = {}
  var meTag = document.getElementById('bootstrap-data-me')
  if (meTag) {
    data.me = JSON.parse(htmlDecode(meTag.innerHTML))
  }

  var eventTag = document.getElementById('bootstrap-data-event')
  if (eventTag) {
    data.event = JSON.parse(htmlDecode(eventTag.innerHTML))
  }

  if (location.hostname === 'events.bizzabo.com') {
    var groupId = data.event.groupId
    var r = new RegExp(`x-bz-access-attendee-token-${groupId}=([A-z0-9]{8}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{12})`)
  } else {
    var r = /x-bz-access-attendee-token=([A-z0-9]{8}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{12})/
  }

  var cookieMatch = document.cookie.match(r)
  if (cookieMatch && cookieMatch.length === 2) {
    data.accessToken = cookieMatch[1]
  }

  if (location.pathname.includes('/agenda/session/')) {
    const splitPath = location.pathname.split('/')
    data.sessionId = splitPath[splitPath.length - 1]
  }

  return data
}