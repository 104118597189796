<template>
  <div class="d3g--chat">
    <chat-channels v-if="showChannelSelector" :channel="selectedChannel" @channel-changed="changeChannel" />
    <template v-if="selectedChannel">
      <div class="d3g--chat-header">
        <div class="d3g--channel-name" v-if="!showChannelSelector">{{ selectedChannel.name }}</div>
        <div class="d3g--channel-description">{{ selectedChannel.description }}</div>
      </div>
      <div class="d3g--chat-container">
        <div class="d3g--announcement" :class="showAnnouncement ? 'd3g--opened' : ''" @click="dismissAnnouncement">
          {{ announcementText }}
          <div class="d3g--click">Dismiss</div>
        </div>
        <chat-log :channel="selectedChannel" />
        <chat-input :channel="selectedChannel" />
      </div>
    </template>
    <template v-else>
      <div class="d3g--spacer"></div>
    </template>
    <div class="d3g--bottom-container" v-if="showChannelSelector">
      <img :src="logoPngUrl" v-if="logoPngUrl" />
      <div class="d3g--close-button" @click="close">Close</div>
    </div>
  </div>
</template>

<script>
import ChatService from '@services/ChatService'
import ChatChannels from '@components/ChatChannels.vue'
import ChatLog from '@components/ChatLog.vue'
import ChatInput from '@components/ChatInput.vue'
import Config from '@services/Config'

export default {
  name: 'chat',
  components: {
    ChatChannels,
    ChatLog,
    ChatInput,
  },
  props: {
    forceChannel: {
      type: Object,
    },
  },
  data () {
    return {
      logoPngUrl: Config.get('logo-png-url'),
      selectedChannel: this.forceChannel || null,
      announcementText: null,
      showAnnouncement: false,
    }
  },
  computed: {
    showChannelSelector () {
      return !this.forceChannel
    },
  },
  methods: {
    changeChannel (channel) {
      this.selectedChannel = channel
    },
    close () {
      this.$emit('close-drawer')
    },
    dismissAnnouncement () {
      this.showAnnouncement = false
      setTimeout(() => this.announcementText = null, 200)
    },
  },
  created () {
    ChatService.subscribeToAnnouncements(announcement => {
      this.announcementText = announcement
      this.showAnnouncement = true
    })
  },
}
</script>

<style scoped lang="scss">
  .d3g--chat {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    padding: 0 0 5px 0;
    background: #ebeeea;
    position: relative;

    .d3g--chat-header {
      flex-basis: 18px;
      padding: 0px 10px 0px 10px;
      background-color: #ebeeea;
      z-index: 1000;

      .d3g--channel-name {
        font-size: 1.0rem;
      }

      .d3g--channel-description {
        padding: 4px 10px;
        font-size: 0.8rem;
        color: #585858;
        background: white;
        border-bottom: 1px solid #e9e9e9;
      }
    }

    .d3g--chat-container {
      display: flex;
      flex-direction: column;
      margin: 0 10px;
      flex-grow: 1;
      position: relative;
    }

    .d3g--announcement {
      background-color: #10eb1e;
      font-size: 0.9rem;
      padding: 4px 10px;
      position: absolute;
      width: 100%;
      top: -50px;
      transition: top 0.2s ease-in, opacity 0.2s ease-in;
      z-index: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;
      overflow-wrap: anywhere;
      opacity: 0;
      line-height: 1.2rem;
      pointer-events: none;
      cursor: pointer;

      &.d3g--opened {
        top: 0;
        opacity: 1;
        pointer-events: initial;
      }

      .d3g--click {
        color: #555;
        font-size: 0.7rem;
        max-width: 50px;
        min-width: 43px;
        margin-left: 3px;
      }
    }

    .d3g--spacer { flex: 1 1 auto; }

    .d3g--bottom-container {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      > img {
        height: 35px;
        padding-left: 10px;
      }

      > .d3g--close-button {
        height: 35px;
        width: 85px;
        padding: 5px 8px;
        margin-right: 10px;
        background-color: #787878;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #F5f5f5;
        border-radius: 0;
        cursor: pointer;

        &:hover {
          color: white;
          background-color: #858585;
        }
      }
    }
  }
</style>