<template>
  <div class="d3g--transcription-logs" ref="d3g-transcription-root">
    <TranscriptionMessage
      :message="message" v-for="message in messages"
      :key="message.transcript_id"
      :isEditable="isEditable"
      @onEditMessage="handleEditMessage"
    />
    <div id="anchor"></div>

    <modal :name="modalName" height="auto">
      <TranscriptionEdit
        :message="messageToEdit"
        @onUpdate="handleUpdateMessage"
      />
    </modal>
  </div>
</template>

<script>
import Config from '@services/Config'
import TranscriptionHandler from '@services/TranscriptionHandler'
import TranscriptionMessage from '@components/TranscriptionMessage.vue'
import TranscriptionEdit from '@components/TranscriptionEdit.vue'

export default {
  name: 'transcription-logs',
  components: {
    TranscriptionMessage,
    TranscriptionEdit,
  },
  props: {
    languageCode: {
      type: String,
    },
    forceConfig: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modalName() {
      return `modal-${this.languageCode}`
    }
  },
  data () {
    return {
      service: new TranscriptionHandler(Config.get('session-id'), this.languageCode, this.forceConfig),
      messages: [],
      hasAutoScrolled: false,
      isEditing: false,
      messageToEdit: null
    }
  },
  methods: {
    fetchTranscripts () {
      this.messages = this.service.transcripts
      if (!this.hasAutoScrolled) {
        this.updateScroll()
      }
    },
    updateScroll () {
      this.$nextTick(() => {
        let el = this.$refs['d3g-transcription-root']
        if (el.scrollHeight > el.offsetHeight) {
          el.scrollTop = el.scrollHeight
          this.hasAutoScrolled = true
        }
      })
    },
    initialize () {
      this.service.init({
        cb: this.fetchTranscripts,
      }).then(() => {
        this.hasAutoScrolled = false
        this.fetchTranscripts()
        this.$emit('initialized', {
          languageOptions: this.service.languageOptions,
          selectedLanguage: this.service.viewingLanguageCode,
        })
      })
    },
    handleEditMessage(messageToEdit) {
      this.messageToEdit = messageToEdit
      this.$modal.show(this.modalName)
    },
    handleUpdateMessage() {
      this.$modal.hide(this.modalName)
    }
  },
  mounted () {
    this.initialize()
  },
  watch: {
    languageCode (newVal, oldVal) {
      if (newVal !== oldVal && oldVal) {
        this.service.destroy()
        this.messages = []
        this.service = new TranscriptionHandler(Config.get('session-id'), this.languageCode)
        this.initialize()
      }
    },
  },
}
</script>

<style lang="scss">
.d3g--transcription-logs {
  width: 100%;
  overflow: auto;
  flex: 1 1 auto;
}

#anchor {
  overflow-anchor: auto;
  height: 1px;
}
</style>
