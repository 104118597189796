import axios from 'axios'
import { sortBy } from 'lodash-es'

import Config from '@services/Config'
import EventBus from '@services/EventBus'

Array.prototype.unique = function () {
  return this.filter(function (value, index, self) {
    return self.indexOf(value) === index
  })
}

const MAX_MESSAGE_COUNT = 1000

class ResourceCenterService {
  _currentUser = null
  _resources = []
  _resourceSections = []

  get resources () {
    return sortBy(this._resources, ['row_order', 'id'])
  }

  get resourceSections () {
    return sortBy(this._resourceSections, ['row_order', 'id'])
  }

  init (meData) {
    this._currentUser = meData
    return this._fetchResources()
      .then(() => {
        console.log('Initializing ResourceCenterService', meData)
      })
  }

  _fetchResources () {
    return axios.get(`${Config.get('base-url')}/resources.json`, {
      withCredentials: true,
      headers: {
        'external-auth-user-id': Config.get('me').id,
        'external-event-id': Config.get('event-id'),
        'external-token': Config.get('bz-auth-token'),
        'd3g-app-key': Config.get('d3g-app-key'),
      }
    }).then((resp) => {
      console.log('resources', resp.data)
      this._resources = resp.data
      this._resources.forEach((resource) => {
        if (!this._resourceSections.find(rs => rs.id === resource.event_resource_section.id)) {
          this._resourceSections.push(resource.event_resource_section)
        }
      })
      // this._resourceSections = this._resources.map(r => r.event_resource_section.name).unique().map(n => ({ name: n, }))
      return resp
    })
  }
}

export default new ResourceCenterService()
