<template>
  <div class="d3g--transcription-moderation-app">
    <div class="d3g--transcription-header">{{ header }}</div>
    <template v-if="transcriptionConfigData !== undefined">
      <TranscriptionLogs
        @initialized="onInit"
        :languageCode="transcriptionConfigData.language_code"
        :forceConfig="transcriptionConfigData"
        :isEditable="transcriptionConfigData.language_code === eventSessionData.primary_language_code"
      />
    </template>
  </div>
</template>

<script>
import { languageNameFromCode } from '@services/TranscriptionHandler'
import TranscriptionLogs from '@components/TranscriptionLogs.vue'

export default {
  name: 'transcription-moderation-app',
  components: {
    TranscriptionLogs,
  },
  props: {
    transcriptionConfig: {
      type: String,
      required: true,
    },
    eventSession: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      loaded: false,
      transcriptionConfigData: undefined,
      eventSessionData: {},
    }
  },
  created () {
    this.transcriptionConfigData = JSON.parse(this.transcriptionConfig)
    this.eventSessionData = JSON.parse(this.eventSession)
  },
  computed: {
    header () {
      return `Transcription for ${languageNameFromCode(this.transcriptionConfigData.language_code, this.transcriptionConfigData.session)}`
    },
  },
  methods: {
    onInit (evt) {
      this.loaded = true
    },
  },
}
</script>

<style scoped lang="scss">
  .d3g--transcription-moderation-app {
    height: 100%;
    width: 100%;
    flex: 0 0 250px;
    display: flex;
    flex-direction: column;
    background: white;
    min-height: 500px;
    max-height: 800px;
    padding: 8px;
    margin-bottom: 15px;
    
    .d3g--transcription-header {
      display: flex;
      flex: 0 0 35px;
      justify-content: space-between;
      padding: 10px;

      .d3g--transcription-language-selector {
        width: 250px;
      }
    }
  }
</style>
