<template>
  <div class="d3g--resource-center-app" ref="d3g-resource-center-root">
    <img class="d3g--resource-center-top-logo" :src="logoPngUrl" v-if="logoPngUrl" />
    <h1 class="d3g--resource-center-title">Resource Center</h1>

    <div class="d3g--resource-center-not-authed" v-if="!hasToken">
      Oops! It looks like you're not logged into the event. <br/><br/>Click on <i>"Looking your ticket?"</i> below to login again and view the Resource Center.
    </div>

    <div v-if="hasToken && loaded">
      <template v-if="isGridMode">
        <template v-if="showGrid">
          <div class="d3g--grid-container">
            <div class="d3g--resource-section-tile"
              :class="{
                'd3g--resource-section-tile-img': !!section.image_url,
                'd3g--resource-section-tile-text': !section.image_url }"
              v-for="section in resourceSections" :key="section.name"
              @click="selectSection(section)">
              <template v-if="!!section.image_url">
                <img :src="section.image_url" />
              </template>
              <template v-else>
                {{ section.name }}
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="d3g--resources-container">
            <h2 class="d3g-resource-section-back" @click="goBackToGrid()">
              <svg viewBox="0 0 20 20" fill="currentColor" style="height:26px; width: 30px; display: inline-block;"><path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
              <span style="display: inline-block;vertical-align: super;">View All Resource Sections</span>
            </h2>

            <h1 class="d3g--resource-section-title" v-if="currentSection.display_name">{{ currentSection.name }}</h1>
            <img class="d3g--resource-section-image" :src="currentSection.image_url" />
            <table>
              <thead>
                <tr>
                  <th>Resource</th>
                  <th>Description</th>
                  <th>Organization</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="resource in currentSection.resources" :key="resource.id">
                  <td>{{ resource.name }}</td>
                  <td v-html="resource.description"></td>
                  <td>{{ resource.event_sponsor.name }}</td>
                  <td><a :href="`${resource.file_url}?external_auth_user_id=${externalUserId}&external_event_id=${eventId}&t=${currentToken}`" target="_blank">{{ resource.type === 'link' ? 'Open Link' : 'Download' }}</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </template>
      <template v-if="isListMode">
        <div class="d3g--resources-container d3g--list-mode" v-for="section in resourceSections" :key="section.name">
          <h1 class="d3g--resource-section-title" v-if="section.display_name">{{ section.name }}</h1>
          <img class="d3g--resource-section-image" :src="section.image_url" />
          <table>
            <thead>
              <tr>
                <th>Resource</th>
                <th>Description</th>
                <th>Organization</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="resource in section.resources" :key="resource.id">
                <td>{{ resource.name }}</td>
                <td v-html="resource.description"></td>
                <td>{{ resource.event_sponsor.name }}</td>
                <td><a :href="`${resource.file_url}?external_auth_user_id=${externalUserId}&external_event_id=${eventId}&t=${currentToken}`" target="_blank">{{ resource.type === 'link' ? 'Open Link' : 'Download' }}</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import ResourceCenterService from '@services/ResourceCenterService'
import Config from '@services/Config'
import EventBus from '@services/EventBus'
import { htmlDecode } from '@utils'

export default {
  name: 'resource-center-app',
  components: {
  },
  data () {
    return {
      loaded: false,
      showGrid: true,
      resourceSections: [],
      resources: [],
      currentSection: {},
      eventId: null,
      externalUserId: null,
      logoPngUrl: Config.get('logo-png-url'),
      hasToken: !!Config.get('bz-auth-token'),
      displayMode: Config.get('resource-center-display-mode'),
      currentToken: Config.get('bz-auth-token'),
    }
  },
  computed: {
    canDisplayGrid () {
      return this.showGrid && this.isGridMode
    },
    isGridMode () {
      return this.displayMode === 'grid'
    },
    isListMode () {
      return this.displayMode === 'list'
    },
  },
  methods: {
    selectSection (section) {
      console.log('selectSection', section)
      this.currentSection = section
      this.showGrid = false
    },
    goBackToGrid () {
      this.currentSection = {}
      this.showGrid = true
    },
  },
  created () {
    this.eventId = Config.get('event-id')
    this.externalUserId = Config.get('me') && Config.get('me').id
    ResourceCenterService.init(Config.get('me'))
      .then(() => {
        this.loaded = true
        this.resourceSections = ResourceCenterService.resourceSections
        this.resourceSections.forEach(rs => rs.resources = ResourceCenterService.resources.filter(r => r.event_resource_section.name === rs.name))
        this.resources = ResourceCenterService.resources
      }, () => {
        this.loaded = false
        this.hasToken = false
      })
  },
}
</script>

<style lang="scss" scoped>
  .d3g--resource-center-app {
    display: block;
    max-width: 1000px;
    margin: 140px auto 0 auto;
    padding-top: 2rem;
    padding-bottom: 13rem;
    background: white;
    font-family: 'BWHaasGrotesk-55Roman-Web', 'Open Sans', sans-serif;
  }

  .d3g--resource-center-title {
    padding: 1.5rem 1rem 0rem 1rem;
    color: #111111;
    font-size: 2.0rem;
    font-weight: 600;
    text-align: center;
    font-family: 'BWHaasGrotesk-75Bold-Web', 'Open Sans', sans-serif;
    border-bottom: 2px solid hsla(123, 84%, 50%, 0.4);
    padding-bottom: 8px;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .d3g--resource-center-not-authed {
    margin-top: 2rem;
    padding: 1.5rem;
    color: #111111;
    font-size: 1.3rem;
    text-align: center;
  }

  .d3g--resource-center-top-logo {
    display: block;
    width: 80%;
    max-width: 350px;
    margin: 0 auto;
    text-align: center;
  }

  .d3g-resource-section-back {
    font-size: 1.1rem;
    color: #333333;
    font-weight: 600;
    padding-bottom: 1rem;
    cursor: pointer;

    &:hover span {
      text-decoration: underline;
      color: #111111;
    }
  }

  .d3g--resources-container {
    padding: 0 1rem 1rem 1rem;
  }

  .d3g--resource-section-title {
    font-size: 1.8rem;
    margin-top: 0;
    padding-bottom: 0.5rem;
    padding-left: 1.3rem;
    font-family: 'BWHaasGrotesk-75Bold-Web', 'Open Sans', sans-serif;
  }

  .d3g--resource-section-image {
    display: block;
    max-width: 300px;
    width: 60%;
    margin: 0 auto;
  }

  .d3g--grid-container {
    display: flex;
    -ms-box-orient: horizontal;
    padding: 0;
    margin: 0;
    list-style: none;
    flex-wrap: wrap;
    padding: 0 1rem;
    width: 100%;
    // border: 1px solid silver;
  }

  .d3g--resource-section-tile {
    height: 200px;
    width: calc(100% * (1/2) - 2rem);
    margin: 1rem;
    background: white;
    border: 4px solid black;
    cursor: pointer;

    line-height: 100px;
    color: black;
    font-weight: bold;
    font-size: 2em;
    text-align: center;

    // center the text absolutely
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.d3g--resource-section-tile-text:hover {
      background: #14eb1e;
      color: #f5f5f5;
    }

    &.d3g--resource-section-tile-img:hover {
      img {
        width: 60%;
      }
    }

    img {
      width: 57%;
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
      transition-property: width;
    }
  }

  .d3g--resources-table {
    padding: 1rem;
  }
</style>

<style lang="scss">
  .d3g--resources-container {
    &.d3g--list-mode {
      margin-top: 45px;

      .d3g--resource-section-title {
        text-align: center;
        padding-left: 0;
      }
    }

    table {
      min-width: 100%;
    }

    table.table-less-column-padding th, table.table-less-column-padding td {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    table.table-less-column-padding-sticky th, table.table-less-column-padding-sticky td {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    table.table-less-column-padding-sticky th {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }

    th {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      border-bottom-width: 1px;
      --border-opacity: 1;
      border-color: #e5e7eb;
      border-color: rgba(229, 231, 235, var(--border-opacity));
      --bg-opacity: 1;
      background-color: #f5f5f5;
      background-color: rgba(249, 250, 251, var(--bg-opacity));
      text-align: left;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 500;
      --text-opacity: 1;
      color: #6b7280;
      color: rgba(107, 114, 128, var(--text-opacity));
      text-transform: uppercase;
      letter-spacing: 0.05em;
    }

    tbody {
      --bg-opacity: 1;
      background-color: #ffffff;
      background-color: rgba(255, 255, 255, var(--bg-opacity));

      tr {
        position: relative;
      }

      tr:not(:last-child):after {
        width: 76%;
        height: 0;
        border-bottom: 1px solid hsla(0, 0, 0, 0.1);
        content: '';
        position: absolute;
        bottom: 0;
        left: 12%;
      }
    }

    td {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom-width: 1px;
      --border-opacity: 1;
      border-color: #e5e7eb;
      border-color: rgba(229, 231, 235, var(--border-opacity));
      font-size: 0.875rem;
      line-height: 1.25rem;
      --text-opacity: 1;
      color: #161e2e;
      color: rgba(22, 30, 46, var(--text-opacity));
    }

    td a {
      --text-opacity: 1;
      color: #1c64f2;
      color: rgba(28, 100, 242, var(--text-opacity));
    }

    td a:hover {
      --text-opacity: 1;
      color: #1c64f2;
      color: rgba(28, 100, 242, var(--text-opacity));
    }

    td a:focus {
      text-decoration: underline;
      outline: 0;
    }

  /*
  Max width before this PARTICULAR table gets nasty
  This query will take effect for any screen smaller than 760px
  and also iPads specifically.
  */
  @media
  only screen and (max-width: 600px) {

      /* Force table to not be like tables anymore */
      table, thead, tbody, th, td, tr {
        display: block;
      }

      /* Hide table headers (but not display: none;, for accessibility) */
      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      tr { border: 1px solid #ccc; }

      td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
      }

      td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
      }

      /*
      Label the data
      */
      td:nth-of-type(1):before { content: "Resource"; }
      td:nth-of-type(2):before { content: "Description"; }
      td:nth-of-type(3):before { content: "Organization"; }
      td:nth-of-type(4):before { content: "Link"; }
    }

  }
</style>
