import axios from 'axios'
import { reject } from 'lodash-es'
import EventBus from '@services/EventBus'
import Config from '@services/Config'
import { harvestContextData } from '@utils'

class TranscriptionHandler {
  _sessionExternalId = null
  _pubnubProvider = null
  _pubnubTranscriptionChannel = null

  _fullTranscript = ''
  _currentPartial = null
  _receivedTranscripts = []

  _subscribedChannels = {}

  _languageOptions = []
  _viewingLanguageCode = null

  _forceConfig = undefined

  constructor (sessionExternalId, languageCode, forceConfig = undefined) {
    this._sessionExternalId = sessionExternalId
    this._viewingLanguageCode = languageCode
    this._forceConfig = forceConfig
  }

  get sessionExternalId () {
    return this._sessionExternalId
  }

  get pubnub () {
    return this._pubnubProvider
  }

  get pubnubTranscriptionChannel () {
    return this._pubnubTranscriptionChannel
  }

  get transcripts () {
    return this._receivedTranscripts
  }

  get languageOptions () {
    return this._languageOptions
  }

  get viewingLanguageCode () {
    return this._viewingLanguageCode
  }

  set viewingLanguageCode (code) {
    this._receivedTranscripts = []
    this._viewingLanguageCode = code
  }

  async init (opts = {}) {
    if (this._forceConfig) {
      return this._initForcedConfig(opts.cb)
    }

    const ctx = harvestContextData()
    Config.set('me', ctx.me)
    Config.set('event-id', ctx.event.id)
    Config.set('bz-auth-token', ctx.accessToken)
    Config.set('session-id', ctx.sessionId)

    let baseUrl = `${Config.get('base-url')}/event_sessions/init/${this.sessionExternalId}`
    if (this.viewingLanguageCode) {
      baseUrl = `${baseUrl}?language_code=${this.viewingLanguageCode}`
    }

    const headers = {
      'external-auth-user-id': Config.get('me').id,
      'external-event-id': Config.get('event-id'),
      'external-token': Config.get('bz-auth-token'),
      'd3g-app-key': Config.get('d3g-app-key'),
      ...Config.get('presigned-token') && { 'presigned-token': Config.get('presigned-token') },
    }
    const initResp = await axios.get(baseUrl, { withCredentials: true, headers })

    console.log('RESP', initResp)
    if (!Config.get('presigned-token') && initResp.headers['presigned-token']) {
      Config.set('presigned-token', initResp.headers['presigned-token'])
    }

    this.completeInitialization(initResp.data, opts.cb)
  }

  completeInitialization (initData, cb) {
    Config.set('session', initData.session)

    this._languageOptions = [
      primaryLanguageOption(initData.session.primary_language_code),
      ...initData.session.translatable_language_codes.split(',').map(c => translatableLanguageOption(c))
    ]
    if (!this._viewingLanguageCode) {
      this._viewingLanguageCode = this.languageOptions[0].value
    }

    Config.set('pubnub-user-uuid', initData.pubnub_configuration.uuid)
    Config.set('pubnub-user-auth-key', initData.pubnub_configuration.auth_key)

    this._receivedTranscripts = initData.transcription_blocks.map(bl => {
      return {
        id: bl.id,
        transcript_id: bl.transcript_id,
        text: bl.text,
        start: bl.start_timestamp,
        end: bl.end_timestamp,
        language: this._viewingLanguageCode,
        partial: false
      }
    })

    this._initPubnub(initData.pubnub_configuration.channel)

    this._pubnubTranscriptionChannel = initData.pubnub_configuration.channel
    this._subscribeChannel(initData.pubnub_configuration.channel, cb)
  }

  async _initForcedConfig (cb) {
    this.completeInitialization(this._forceConfig, cb)
  }

  _initPubnub () {
    this._pubnubProvider = new PubNub({
      subscribeKey: Config.get('pubnub-subscribe-key'),
      publishKey: Config.get('pubnub-publish-key'),
      uuid: Config.get('pubnub-user-uuid'),
      authKey: Config.get('pubnub-user-auth-key'),
      ssl: true,
    })

    let that = this
    this.pubnub.addListener({
      message: function (evt) {
        const message = evt.message
        if (message.language !== that._viewingLanguageCode) {
          return
        }

        if (message.partial && !Config.get('session').show_partials) {
          return
        }

        console.log('Incoming message', message)
        
        const existingMessageIndex = that._receivedTranscripts.findIndex((m) => m.transcript_id === message.transcript_id)
        
        if (existingMessageIndex > -1) {
          const existingMessage = that._receivedTranscripts[existingMessageIndex]

          // Discard if new message is partial, but existing is final.
          if (!existingMessage.partial && message.partial) {
            return
          }

          that._receivedTranscripts.splice(existingMessageIndex, 1, message)
        } else {
          // that._receivedTranscripts = reject(that._receivedTranscripts, d => d.partial)
          that._receivedTranscripts.push(message)
        }

        EventBus.dispatch(`d3g-mods:message-received:${evt.channel}`)
      },
    })
  }

  _subscribeChannel (channel, cb) {
    this.pubnub.subscribe({ channels: [channel] })
    if (cb) {
      this._subscribedChannels[channel] = {
        eventBusUnsub: EventBus.subscribe(`d3g-mods:message-received:${channel}`, cb),
      }
    }
  }

  destroy () {
    this.pubnub.unsubscribeAll()
    Object.values(this._subscribedChannels).forEach(ch => ch.eventBusUnsub())
  }
}

export function languageNameFromCode (code, sessionRecord) {
  return sessionRecord.primary_language_code === code
    ? TRANSCRIBE_LANGUAGES[code]
    : TRANSLATABLE_LANGUAGES[code]
}

function primaryLanguageOption (code) {
  return { label: TRANSCRIBE_LANGUAGES[code], value: code }
}

function translatableLanguageOption (code) {
  return { label: TRANSLATABLE_LANGUAGES[code], value: code }
}

const TRANSCRIBE_LANGUAGES = {
  'en-US': 'English (US)',
  'en-AU': 'English (Australian)',
  'en-GB': 'English (British)',
  'es-US': 'Spanish (US)',
  'fr-FR': 'French',
  'fr-CA': 'French (Canadian)',
  'de-DE': 'German',
  'ja-JP': 'Japanese',
  'ko-KR': 'Korean',
  'pt-BR': 'Portuguese (Brazil)',
  'it-IT': 'Italian',
}

const TRANSLATABLE_LANGUAGES = {
  'af': 'Afrikaans',
  'sq': 'Albanian',
  'am': 'Amharic',
  'ar': 'Arabic',
  'hy': 'Armenian',
  'az': 'Azerbaijani',
  'bn': 'Bengali',
  'bs': 'Bosnian',
  'bg': 'Bulgarian',
  'ca': 'Catalan',
  'zh': 'Chinese (Simplified)',
  'zh-TW': 'Chinese (Traditional)',
  'hr': 'Croatian',
  'cs': 'Czech',
  'da': 'Danish',
  'fa-AF': 'Dari',
  'nl': 'Dutch',
  'en': 'English',
  'et': 'Estonian',
  'fa': 'Farsi (Persian)',
  'tl': 'Filipino Tagalog',
  'fi': 'Finnish',
  'fr': 'French',
  'fr-CA': 'French (Canada)',
  'ka': 'Georgian',
  'de': 'German',
  'el': 'Greek',
  'gu': 'Gujarati',
  'ht': 'Haitian Creole',
  'ha': 'Hausa',
  'he': 'Hebrew',
  'hi': 'Hindi',
  'hu': 'Hungarian',
  'is': 'Icelandic',
  'id': 'Indonesian',
  'it': 'Italian',
  'ja': 'Japanese',
  'kn': 'Kannada',
  'kk': 'Kazakh',
  'ko': 'Korean',
  'lv': 'Latvian',
  'lt': 'Lithuanian',
  'mk': 'Macedonian',
  'ms': 'Malay',
  'ml': 'Malayalam',
  'mt': 'Maltese',
  'mn': 'Mongolian',
  'no': 'Norwegian',
  'fa': 'Persian',
  'ps': 'Pashto',
  'pl': 'Polish',
  'pt': 'Portuguese',
  'ro': 'Romanian',
  'ru': 'Russian',
  'sr': 'Serbian',
  'si': 'Sinhala',
  'sk': 'Slovak',
  'sl': 'Slovenian',
  'so': 'Somali',
  'es': 'Spanish',
  'es-MX': 'Spanish (Mexico)',
  'sw': 'Swahili',
  'sv': 'Swedish',
  'tl': 'Tagalog',
  'ta': 'Tamil',
  'te': 'Telugu',
  'th': 'Thai',
  'tr': 'Turkish',
  'uk': 'Ukrainian',
  'ur': 'Urdu',
  'uz': 'Uzbek',
  'vi': 'Vietnamese',
  'cy': 'Welsh',
}

export default TranscriptionHandler
