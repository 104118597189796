import axios from "axios";
import memoize from "lodash/memoize";
import Config from "@services/Config";

const api = memoize(() =>
  axios.create({
    baseURL: Config.get("base-url"),
    headers: {
      'Content-Type': 'application/json'
    }
  })
);

export function updateEventSession(id, updatedEventSession) {
  return api().patch(`/event_sessions/${id}.json`, updatedEventSession);
}

export function updateTranscriptionBlock(id, transcript) {
  return api().put(`/event_sessions/transcription_blocks/${id}`, transcript)
}
