/**
 * Takes in current location and returns formatted base path
 * @param {Location} location Pass in the `window.location`
 * @returns {string}
 */
export function getCurrentBasePath(location) {
  const url = new URL(location)
  url.pathname = ''

  return url.toString()
}
