import axios from 'axios'
import { filter } from 'lodash-es'

import Config from '@services/Config'
import EventBus from '@services/EventBus'

const MAX_MESSAGE_COUNT = 1000

class ChatService {
  _messagesByChannel = {}
  _subscribedChannels = {}
  _provider = null
  _adminChannelIdentifier = null

  _currentAnnouncement = null
  _announcementCallbacks = []

  init (meData, configuration = null) {
    const initPubNub = (configObj) => {
      this._availableChannels = configObj.channels
      this._adminChannelIdentifier = configObj.admin_channel

      this._provider = new PubNub({
        subscribeKey: Config.get('pubnub-subscribe-key'),
        publishKey: Config.get('pubnub-publish-key'),
        uuid: configObj.chat_configuration.uuid,
        authKey: configObj.chat_configuration.auth_key,
        ssl: true,
      })

      const that = this
      this._provider.addListener({
        message: function (msg) {
          if (msg.channel === that._adminChannelIdentifier) {
            that._handleAdminMessage(msg)
          } else {
            that._logMessage(msg)
          }
        },
      })

      this.subscribeChannel(this._adminChannelIdentifier)

      EventBus.subscribe('d3g-mods:announcement-received', announcement => {
        this._announcementCallbacks.forEach(cb => cb(announcement))
      })
    }

    if (configuration) {
      return new Promise(resolve => {
        return resolve(initPubNub(configuration))
      })
    } else {
      return this._fetchInitialData()
        .then(res => {
          return initPubNub(res)
        })
    }
  }

  get messagesByChannel () {
    return this._messagesByChannel
  }

  get availableChannels () {
    return this._availableChannels
  }

  get currentAnnouncement () {
    return this._currentAnnouncement
  }

  get me () {
    return Config.get('me')
  }

  get userIsAdmin () {
    return this.me.uuid.includes('admin:')
  }

  hasSubscriptionToChannel (channel) {
    return !!this._subscribedChannels[channel.identifier]
  }

  subscribeChannel (channel, cb) {
    this._provider.subscribe({
      channels: [channel],
    })
    this._subscribedChannels[channel] = {
      eventBusUnsub: EventBus.subscribe(`d3g-mods:message-received:${channel}`, cb),
    }
  }

  unsubscribeChannel (channel) {
    this._provider.unsubscribe({
      channels: [channel]
    })
    this._subscribedChannels[channel].eventBusUnsub()
    delete this._subscribedChannels[channel]
    delete this._messagesByChannel[channel]
  }

  subscribeToAnnouncements (cb) {
    this._announcementCallbacks.push(cb)
  }

  sendAnnouncement (text) {
    if (window.confirm('Are you sure you want to send this announcement? It will be sent to every single conference attendee.')) {
      this._provider.publish({
        message: {
          action: 'announce',
          text,
        },
        channel: this._adminChannelIdentifier,
      })
    }
  }

  sendMessage (messageText, channel) {
    if (!messageText || !channel) {
      console.error('Cannot send message without text or channel.')
      return false
    }

    const me = Config.get('me')
    let name = `${me.firstName} ${me.lastName}`
    if (me.firstName && !me.lastName) {
      name = me.firstName
    } else if (!me.firstName && me.lastName) {
      name = me.lastName
    } else if (!me.firstName && !me.lastName) {
      name = 'Unknown'
    }
    return this._provider.publish({
      message: {
        text: messageText,
        name: name,
        publisher: me.uuid,
      },
      channel,
    })
  }

  deleteMessage (msg) {
    if (window.confirm('Are you sure you want to delete this message? This action cannot be undone.')) {
      return axios.post(`${Config.get('base-url')}/chat_channels/${this._getChatChannelId(msg.channel)}/delete_message`, {
        timetoken: msg.timetoken,
        event_id: this._getChatChannelEventId(msg.channel),
      }, {
        withCredentials: true,
        headers: {
          'external-event-id': Config.get('event-id'),
          'd3g-app-key': Config.get('d3g-app-key'),
          'X-CSRF-Token': Config.get('csrf-token'),
        },
      })
    }
    return new Promise(r => r())
  }

  banUser (msg) {
    if (window.confirm('Are you sure you want to ban this user? This action cannot be undone.')) {
      return axios.post(`${Config.get('base-url')}/external_users/ban`, {
        uuid: msg.publisher,
        event_id: this._getChatChannelEventId(msg.channel),
      }, {
        withCredentials: true,
        headers: {
          'external-event-id': Config.get('event-id'),
          'd3g-app-key': Config.get('d3g-app-key'),
          'X-CSRF-TOKEN': Config.get('csrf-token'),
        },
      })
    }
    return new Promise(r => r())
  }

  checkForName () {
    const me = Config.get('me')
    if (!me.firstName && !me.lastName) {
      return this._fetchInitialData()
    }
    return true
  }

  fetchHistoryAsAdmin (channel, currentTimestamp) {
    const chatChannel = this.availableChannels.find(cc => cc.identifier === channel)
    const startTimestamp = this.newHistoryTimestamp(currentTimestamp, chatChannel)
    const maxCount = chatChannel.history_max_messages_returned
    return this._provider.history({
      channel,
      start: startTimestamp,
      end: currentTimestamp,
      stringifiedTimeToken: true,
    }).then(res => {
      const messages = [...res.messages].reverse()

      for (let i = 0; i < messages.length; i++) {
        const msgData = messages[i]
        this._logHistoryMessage(channel, {
          channel,
          message: msgData.entry,
          publisher: msgData.entry.publisher,
          timetoken: msgData.timetoken,
        })
        if (i + 1 >= maxCount) {
          res.newHistoryCurrentTimestamp = msgData.timetoken - 1000
          break
        }
        res.newHistoryCurrentTimestamp = startTimestamp
      }

      if (messages.length === 0) {
        res.newHistoryCurrentTimestamp = startTimestamp
      }

      return res
    })
  }

  fetchHistoryAsUser (channel, currentTimestamp) {
    const chatChannel = this.availableChannels.find(cc => cc.identifier === channel)
    const startTimestamp = this.newHistoryTimestamp(currentTimestamp, chatChannel)
    const maxCount = chatChannel.history_max_messages_returned
    return this._provider.history({
      channel,
      end: startTimestamp,
      count: maxCount,
      stringifiedTimeToken: true,
    }).then(res => {
      const messages = [...res.messages].reverse()
      for (let i = 0; i < messages.length; i++) {
        const msgData = messages[i]
        this._logHistoryMessage(channel, {
          channel,
          message: msgData.entry,
          publisher: msgData.entry.publisher,
          timetoken: msgData.timetoken,
        })
      }
      return res
    })
  }

  newHistoryTimestamp (currentTimestamp, chatChannel) {
    const offset = chatChannel.history_max_minutes_returned * 60 * Math.pow(10, 7)
    return currentTimestamp - offset
  }

  _enrichMessage (msg, userMetadata) {
    return {
      ...msg,
      userMetadata,
    }
  }

  _logMessage (msg) {
    if (!this._messagesByChannel[msg.channel]) {
      this._messagesByChannel[msg.channel] = []
    }

    if (this._messageAlreadyExists(msg, this._messagesByChannel[msg.channel])) {
      return false
    }

    this._messagesByChannel[msg.channel].push(msg)
    this._balanceMessageCount(msg.channel)
    EventBus.dispatch(`d3g-mods:message-received:${msg.channel}`)
  }

  _logHistoryMessage (channel, msg) {
    if (!this._messagesByChannel[channel]) {
      this._messagesByChannel[channel] = []
    }
    this._messagesByChannel[channel].unshift(msg)
    this._balanceMessageCount(channel)
    EventBus.dispatch(`d3g-mods:message-received:${channel}`)
  }

  _balanceMessageCount (channel) {
    while (this._messagesByChannel[channel].length > MAX_MESSAGE_COUNT) {
      this._messagesByChannel[channel].shift()
    }
  }

  _messageAlreadyExists (msg, messageList) {
    return !!messageList.find(m => m.timetoken === msg.timetoken && m.publisher === msg.publisher)
  }

  _fetchInitialData () {
    const me = Config.get('me')
    return axios.get(`${Config.get('base-url')}/apps/start`, {
      withCredentials: true,
      headers: {
        'external-auth-user-id': me.id,
        'external-event-id': Config.get('event-id'),
        'external-token': Config.get('bz-auth-token'),
        'd3g-app-key': Config.get('d3g-app-key'),
      }
    }).then(resp => {
      Config.set('pubnub-auth-key', resp.data.chat_configuration.auth_key)

      // Set the name returned
      if (!me.firstName && !me.lastName) {
        Config.set('me', {
          ...me,
          firstName: resp.data.chat_configuration.first_name,
          lastName: resp.data.chat_configuration.last_name,
        })
      }
      let tempMe = Config.get('me')
      Config.set('me', {
        ...tempMe,
        uuid: resp.data.chat_configuration.uuid,
      })
      return resp.data
    })
  }

  _handleAdminMessage (msg) {
    if (msg.message.action === 'delete') {
      this._messagesByChannel[msg.message.channel] = filter(
        this._messagesByChannel[msg.message.channel],
        m => m.timetoken !== msg.message.timetoken,
      )
    } else if (msg.message.action === 'announce') {
      this._currentAnnouncement = msg.message.text
      EventBus.dispatch(`d3g-mods:announcement-received`, msg.message.text)
    } else if (msg.message.action === 'user_banned' && msg.message.uuid === this.me.uuid) {
      window.alert('You have been banned from the chat.')
    }
    EventBus.dispatch(`d3g-mods:message-received:${msg.message.channel}`)
  }

  _getChatChannelId (channelIdentifier) {
    const channel = this.availableChannels.find(ch => ch.identifier === channelIdentifier)
    return channel ? channel.id : null
  }

  _getChatChannelEventId (channelIdentifier) {
    const channel = this.availableChannels.find(ch => ch.identifier === channelIdentifier)
    return channel ? channel.event_id : null
  }
}

export default new ChatService()
