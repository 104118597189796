<template>
  <div class="d3g--transcription-app">
    <div class="d3g--transcription-header">
      Transcription
      <v-select class="d3g--transcription-language-selector"
        v-model="selectedLanguage"
        :options="languageOptions"
        :reduce="o => o.value"
        :clearable="false"
      ></v-select>
    </div>
    <TranscriptionLogs @initialized="onInit" :languageCode="selectedLanguage" :isEditable="false" />
  </div>
</template>

<script>
import TranscriptionLogs from '@components/TranscriptionLogs.vue'

export default {
  name: 'transcription-app',
  components: {
    TranscriptionLogs,
  },
  data () {
    return {
      loaded: false,
      selectedLanguage: null,
      languageOptions: [],
    }
  },
  methods: {
    onInit (evt) {
      this.languageOptions = evt.languageOptions
      this.selectedLanguage = evt.selectedLanguage
      this.loaded = true
    },
  },
}
</script>

<style scoped lang="scss">
  .d3g--transcription-app {
    max-height: 500px;
    height: 100%;
    width: 100%;
    flex: 0 0 250px;
    display: flex;
    flex-direction: column;
    background: white;
    max-height: 555px;
    padding: 8px;
    
    .d3g--transcription-header {
      display: flex;
      flex: 0 0 35px;
      justify-content: space-between;
      padding: 10px;

      .d3g--transcription-language-selector {
        width: 250px;
      }
    }
  }
</style>
