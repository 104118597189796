// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('utils/direct_uploads')

import '../stylesheets/application.scss'
require('alpinejs')

import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'

import ChatModeration from '../ChatModeration.vue'
import ChatApp from '../ChatApp.vue'
import ResourceCenterApp from '../ResourceCenterApp.vue'
import TranscriptionApp from '../TranscriptionApp.vue'
import TranscriptionModeration from '../TranscriptionModeration.vue'
import EventSessionStatus from '../EventSessionStatus.vue'
import Config from '@services/Config'
import { getCurrentBasePath } from 'utils/url'

Vue.component('v-select', vSelect)
Vue.use(VModal)

document.addEventListener('turbolinks:load', () => {
  flatpickr('.datetimepicker', {
    enableTime: true,
    dateFormat: 'm-d-Y G:iK',
  })

  flatpickr('.datepicker')

  Config.set('base-url', getCurrentBasePath(window.location))
  Config.set('csrf-token', document.querySelector('meta[name="csrf-token"]').getAttribute('content'))
  Config.set('pubnub-publish-key', window.vueVars.pubnub_publish_key)
  Config.set('pubnub-subscribe-key', window.vueVars.pubnub_subscribe_key)
  Config.set('pubnub-secret-key', window.vueVars.pubnub_secret_key)
  Config.set('node-server-url', window.vueVars.node_server_url)

  if (document.getElementById('chat-moderation-app')) {
    new Vue({
      el: '#chat-moderation-app',
      render: h => {
        const el = document.getElementById('chat-moderation-app')
        const context = {
          props: {
            ...el.dataset,
          },
        }
        return h(ChatModeration, context)
      },
    })
  }

  if (document.getElementById('chat-app')) {
    const meData = {
      "id": 4492836,
      "groupId": 30942,
      "firstName": "User",
      "lastName": (Math.floor(Math.random() * 100) + 1).toString(),
      "company": "Webb Integrated",
      "title": "Developer",
      "country": "US",
      "primaryEmail": "tyler@webbintegrated.com",
      "primaryEmailValid": true,
      "status": "user",
      "emails": [
        "tyler@webbintegrated.com"
      ],
      "isJoined": false,
      "uuid": "tyler@webbintegrated.com",
    }

    // const meTag = document.getElementById('bootstrap-data-me')
    // const meData = JSON.parse(htmlDecode(meTag.innerHTML))

    // const eventTag = document.getElementById('bootstrap-data-event')
    // const eventData = JSON.parse(htmlDecode(eventTag.innerHTML))

    Config.set('me', meData)
    Config.set('event-id', 229645)//eventData.id)

    // const cookieMatch = document.cookie.match(/x-bz-access-attendee-token=([A-z0-9]{8}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{4}-[A-z0-9]{12})/)
    Config.set('bz-auth-token', 'e21deafe-9f38-4798-9a53-94f841ded2d0') //cookieMatch[1])
    console.log('cookie', Config.get('bz-auth-token'))

    Config.set('d3g-app-key', 'abc123')

    new Vue({
      el: '#chat-app',
      render: h => {
        const el = document.getElementById('chat-app')
        const context = {
          props: {
            ...el.dataset,
          },
        }
        return h(ChatApp, context)
      },
    })
  }

  if (document.getElementById('resource-center-app')) {
    new Vue({
      el: '#resource-center-app',
      render: h => h(ResourceCenterApp),
    })
  }

  const transModApps = document.querySelectorAll('.transcription-moderation-app')
  if (transModApps.length > 0) {
    transModApps.forEach(el => {
      new Vue({
        el,
        render: h => {
          const context = {
            props: { ...el.dataset },
          }
          return h(TranscriptionModeration, context)
        },
      })
    })
  }

  const transcriptionAppContainer = document.getElementById('transcription-app')
  if (transcriptionAppContainer) {
    const meData = {
      "id": 4492836,
      "groupId": 30942,
      "firstName": "User",
      "lastName": (Math.floor(Math.random() * 100) + 1).toString(),
      "company": "Webb Integrated",
      "title": "Developer",
      "country": "US",
      "primaryEmail": "tyler@webbintegrated.com",
      "primaryEmailValid": true,
      "status": "user",
      "emails": [
        "tyler@webbintegrated.com"
      ],
      "isJoined": false,
      "uuid": "tyler@webbintegrated.com",
    }

    // Config.set('base-url', opts.baseUrl)
    Config.set('me', meData)
    Config.set('event-id', 229645)
    Config.set('session-id', transcriptionAppContainer.dataset.sessionExternalId)

    Config.set('d3g-app-key', 'xoYum8XJ8CThhBERiVxwlegsFPSkzCCI7YAg9qe3Icz7PGk0EILn661dyjfF4wVi')
    Config.set('bz-auth-token', '51d157fc-c1cf-4b83-bbd9-9439f4c4e89b') //cookieMatch[1])
    console.log('cookie', Config.get('bz-auth-token'))

    // Config.set('logo-png-url', opts.logoPngUrl)
    // Config.set('pubnub-publish-key', opts.pubnubPublishKey)
    // Config.set('pubnub-subscribe-key', opts.pubnubSubscribeKey)
  
    new Vue({
      el: '#transcription-app',
      render: h => h(TranscriptionApp),
    })
  }

  const eventStatusContainer = document.getElementById('event-session-status-component')
  if (eventStatusContainer) {
    new Vue({
      el: '#event-session-status-component',
      render: h => {
        const context = {
          props: { ...eventStatusContainer.dataset },
        }
        return h(EventSessionStatus, context)
      },
    })
  }
})
