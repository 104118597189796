<template>
  <div class="d3g--transcription-message">
    <div class="d3g--transcription-message--timestamp">
      <div>{{ timestamp }}</div>
      <div class="d3g--transcription-message--edit" v-if="isMessageEditable">
        <button v-on:click="handleEditMessage">Edit</button>
      </div>
    </div>
    <div class="d3g--transcription-message--text">{{ message.text }}</div>
  </div>
</template>

<script>
export default {
  name: 'transcription-message',
  props: {
    message: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      hours: null,
      minutes: null,
      seconds: null,
    }
  },
  computed: {
    timestamp () {
      const hours = Math.floor(this.message.start / (60 * 60))
      const minutes = Math.floor((this.message.start - hours * (60 * 60)) / 60)
      const seconds = Math.floor((this.message.start - hours * (60 * 60) - minutes * 60) % 60)
      return hours > 0 ? `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}` : `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    },
    isMessageEditable() {
      return this.isEditable && !this.message.partial
    }
  },
  methods: {
    handleEditMessage() {
      this.$emit('onEditMessage', this.message)
    }
  }
}
</script>

<style lang="scss">
.d3g--transcription-message {
  display: flex;
  overflow-anchor: none;
  align-items: flex-start;
  min-height: 32px;
  margin-bottom: 5px;

  .d3g--transcription-message--timestamp {
    color: #777;
    font-size: 0.7rem;
    margin-right: 5px;
  }
}

.d3g--transcription-message--edit {
  opacity: 0;
  transition: opacity 250ms ease-in-out;

  .d3g--transcription-message:hover & {
    opacity: 1;
  }
}
</style>
