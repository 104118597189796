<template>
  <div class="d3g--chat-moderation-app" v-if="loaded">
    <div class="d3g--chat-wrapper" v-for="ch in allChannels" :key="ch.identifier">
      <chat :force-channel="ch" />
    </div>
    <div class="d3g--announcement-wrapper">
      <div class="d3g--announcement-label">Send an Announcement</div>
      <div class="d3g--announcement-container">
        <div class="d3g--textarea-wrapper">
          <span class="textarea" role="textbox" ref="announcement-input-box" contenteditable></span>
        </div>
        <button @click="sendAnnouncement">Send</button>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import ChatService from '@services/ChatService'
import Chat from '@components/Chat.vue'
import Config from '@services/Config'
import EventBus from '@services/EventBus'
import { htmlDecode } from '@utils'

export default {
  name: 'chat-moderation-app',
  components: {
    Chat,
  },
  props: {
    chatConfiguration: {
      type: String,
      required: true,
    },
    currentUser: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      loaded: false,
      allChannels: [],
      chatConfigurationData: null,
      currentUserData: null,
      announcementText: '',
    }
  },
  methods: {
    sendAnnouncement () {
      const announcementText = this.$refs['announcement-input-box'].innerText
      ChatService.sendAnnouncement(announcementText)
      this.$refs['announcement-input-box'].innerText = ''
    },
  },
  created () {
    this.chatConfigurationData = JSON.parse(this.chatConfiguration)
    this.currentUserData = JSON.parse(this.currentUser)

    const [ firstName, lastName ] = this.currentUserData.name.split(' ')
    Config.set('me', {
      firstName,
      lastName,
      email: this.currentUserData.email,
      uuid: this.currentUserData.pubnub_uuid,
    })

    Config.set('pubnub-auth-key', this.chatConfigurationData.chat_configuration.auth_key)

    ChatService.init(Config.get('me'), this.chatConfigurationData)
      .then(() => {
        this.loaded = true
        this.allChannels = ChatService.availableChannels
      })
  },
}
</script>

<style lang="scss" scoped>
  .d3g--chat-moderation-app {
    display: flex;

    .d3g--chat-wrapper {
      width: 300px;
      height: 600px;
      border: 1px solid black;
      margin: 5px;
    }

    .d3g--announcement-wrapper {
      width: 300px;
      height: 300px;

      .d3g--announcement-label {
        font-size: 1.2rem;
        margin-bottom: 6px;
      }

      .d3g--announcement-container {
        display: flex;

        > .d3g--textarea-wrapper {
          position: relative;
          height: auto;
          padding: 4px 8px;
          flex-grow: 1;
          border: 1px solid #9b9b9b;
          border-radius: 0;
          border-right-width: 0px;
          background: white;

          span {
            display: block;
            width: 100%;
            max-width: 100%;
            min-height: 1.3rem;
            overflow-y: auto;
            overflow-x: hidden;
            overflow-wrap: anywhere;
            font-size: 0.9rem;
            max-height: 120px;
            outline-width: 0;
            border-radius: 0;
          }
        }

        > button {
          flex: 0 0 85px;
          margin-left: 0;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          padding-left: 1rem;
          padding-right: 1rem;
          background-color:
          #9b9b9b;
          font-size: 0.875rem;
          font-weight: 500;
          border-width: 0;
          border-radius: 0;
          color:
          rgba(255, 255, 255, 0.95);
          box-shadow: 0 1px 2px 0
          rgba(0, 0, 0, 0.05);
          transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
          transition-duration: 150ms;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          cursor: pointer;

          &:hover {
            font-weight: 600;
            color: white;
          }
        }
      }

    }
  }
</style>

<style lang="scss">
  .d3g--chat-button {
    position: fixed;
    bottom: -5px;
    right: 25px;
    width: 150px;
    height: 45px;
    border-radius: 5px;
    background-color: #652f6c;
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: lighten(#652f6c, 10%);
    }
  }
</style>
