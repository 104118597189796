class Config {
  _config = {}

  set (key, val) {
    this._config[key] = val
  }

  get (key) {
    return this._config[key]
  }
}

const c = new Config()
export default c
